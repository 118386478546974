import React from 'react';
import Button from '../../components/ui/Button';
import { css, cx } from 'linaria';
import { theme } from '../../components/Theme';
import useChildren from '../../hooks/useChildren';
import MaxWidth from '../../components/Layout/MaxWidth';
import Styled from './Style';

// Define a base CSS class for the Button
const buttonBaseStyle = css`
  svg path {
    fill: currentColor;
  }
  :hover:not(:disabled) svg path {
    fill: currentColor;
  }
`;

export default function ColouredQuoteBlock(props) {
  const {
    align = 'left',
    linkUrl,
    linkText,
    children,
    withBorder,
    borderColour = '#666666',
    backgroundColour = '#666666',
    textColour = '#ffffff'
  } = useChildren(props);

  const content = props.content;

  return (
    <MaxWidth>
      <Styled.ColouredQuoteWrapper>
        <section className={cx(infoBlockClassName, align && `align-${align}`)}>
          <Styled.ColouredQuote>
            <section
              style={{
                backgroundColor: backgroundColour,
                borderColor: borderColour
              }}
              className={cx(withBorder && withBorderBlock)}
            >
              <div className="contentWrapper">
                {content || children ? (
                  <div className="content">
                    {content?.type === 'html' && (
                      <div
                        className="html"
                        dangerouslySetInnerHTML={{
                          __html:
                            content?.value?.value || content?.value || null
                        }}
                        style={{ color: textColour }}
                      />
                    )}
                    {children}
                  </div>
                ) : null}
                {linkUrl && linkText ? (
                  <footer className="footer">
                    <Button
                      className={cx('button', buttonBaseStyle)}
                      href={linkUrl}
                      style={{
                        color: backgroundColour,
                        backgroundColor: textColour,
                        borderColor: textColour
                      }}
                    >
                      {linkText}
                    </Button>
                  </footer>
                ) : null}
              </div>
            </section>
          </Styled.ColouredQuote>
        </section>
      </Styled.ColouredQuoteWrapper>
    </MaxWidth>
  );
}

const withBorderBlock = css`
  border: 2px solid;
`;

const infoBlockClassName = css`
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: start;
  position: relative;
  z-index: 1;
  width: 100%;
  margin-bottom: 1rem;
  &.align-center {
    text-align: center;
    align-items: center;
    .contentWrapper {
      align-items: center;
    }
  }
  &.align-right {
    text-align: right;
    align-items: end;
    .contentWrapper {
      align-items: end;
    }
  }
  ${theme.above.md} {
    &.imageAlign-left {
      flex-direction: row;
      align-items: center;
      .contentWrapper {
        padding-left: 2.25rem;
      }
    }
    &.imageAlign-right {
      flex-direction: row-reverse;
      align-items: center;
      .contentWrapper {
        padding-right: 2.25rem;
      }
    }
    &.imageAlign-top {
      .imageWrapper {
        flex: 1 1 100%;
      }
    }
  }
  ${theme.above.xl} {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
  }

  .imageWrapper {
    flex: 1 1 50%;
    margin: 0;
    width: 100%;
  }
  .contentWrapper {
    flex: 1 1 50%;
    padding: 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;

    ${theme.above.xl} {
      max-width: 90rem;
      padding: 1rem 2.25rem 1rem;
    }
  }
  .content {
    .html {
      line-height: 1.5rem;
      h1 {
        line-height: normal;
      }

      a {
        color: inherit;
      }
      p {
        margin: 0.5rem 0;
      }
      strong {
        font-weight: ${theme.fontWeights.medium};
      }
      ul {
        list-style: initial;
        padding: 1rem 0 1rem 1.5rem;
      }
    }
  }
  .footer {
    margin-top: 1rem;
  }
`;
