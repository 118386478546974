import { styled } from 'linaria/react';
import MaxWidth from '../../components/Layout/MaxWidth';
import { theme } from '../../components/Theme';
import { toRem } from '../../helpers';
import Heading from '../../components/ui/Heading';

const ContentContainer = styled.div``;

const ContentBlockV2Container = styled(MaxWidth)`
  padding: 0;
  display: block;
  margin-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;

  ${theme.above.lg} {
    padding-right: 2.25rem;
    padding-left: 2.25rem;
  }
`;

const ContentBlockV2Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  padding: ${props => (props.hasFrame ? '2.25rem' : '0')};
  background-color: ${props =>
    props.hasFrame ? props.frameColour : '#ffffff'};

  ${theme.above.sm} {
    flex-direction: row;
  }
`;

const InnerWrapper = styled('div')`
  height: 100%;
  width: 100%;
  min-height: ${toRem(357)};
  padding: ${toRem(24)} ${toRem(24)} ${toRem(37)} ${toRem(24)};
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 5;
  ${theme.above.sm} {
    min-height: ${toRem(356)};
  }
`;

const IconImage = styled('img')`
  width: ${toRem(32)};
  height: ${toRem(32)};
  margin-bottom: ${toRem(20)};
  position: absolute;
  top: 20px;
`;

const ImageWrapper = styled('div')`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
`;

const TextColour = styled('span')`
  color: ${props => props.textColour};
`;

const Title = styled(Heading)`
  margin-bottom: ${toRem(18)};
  font-size: ${toRem(24)};
  line-height: ${toRem(25)};
  font-weight: ${theme.fontWeights.bold};

  ${theme.above.sm} {
    max-width: 75%;
  }

  ${theme.above.md} {
    max-width: 50%;
  }

  ${theme.above.xl} {
    max-width: 50%;
  }
`;

const Text = styled('p')`
  line-height: ${toRem(22)};
  color: ${theme.colorScheme.white};
  margin-bottom: ${toRem(32)};

  ${theme.above.xs} {
    max-width: 60%;
  }

  ${theme.above.sm} {
    max-width: 75%;
  }

  ${theme.above.md} {
    max-width: 50%;
  }

  ${theme.above.xl} {
    max-width: 50%;
  }
`;

const FrostedGlassV2 = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;

  ::before {
    content: '';
    width: ${toRem(700)};
    height: ${toRem(700)};
    position: absolute;
    top: 0;
    left: ${toRem(-400)};
    background: rgba(0, 0, 0, 0.5);
    transform: rotate(-18deg);
    backdrop-filter: blur(4px);

    ${theme.above.xs} {
      left: ${toRem(-433)};
    }

    ${theme.above.lg} {
      left: ${toRem(-400)};
    }
    ${theme.above.xl} {
      left: ${toRem(-345)};
    }
  }
`;

const ContentBlockItemV2Wrapper = styled('div')`
  min-height: ${toRem(357)};
  position: relative;
  margin-bottom: ${toRem(16)};
  overflow: hidden;
  border-radius: ${props => (props.rounded ? '30px' : '0px')};

  &:last-child {
    margin-bottom: 0;
  }

  ${theme.above.sm} {
    min-height: ${toRem(356)};
    flex: 1;
    margin-bottom: 0;
    margin-right: ${toRem(16)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const style = {
  ContentContainer,
  ContentBlockV2Container,
  ContentBlockV2Wrapper,
  ContentBlockItemV2Wrapper,
  InnerWrapper,
  IconImage,
  ImageWrapper,
  TextColour,
  Title,
  Text,
  FrostedGlassV2
};

export default style;
