import { styled } from 'linaria/react';
import MaxWidth from '../../components/Layout/MaxWidth';
import { theme } from '../../components/Theme';
import { toRem } from '../../helpers';

const CategoryBlockWrapper = styled(MaxWidth)`
  margin-bottom: 2rem;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: auto;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  ::-webkit-scrollbar {
    display: none;
  }

  ${theme.above.xl} {
    overflow-x: initial;
  }
`;

const CategoryTextContent = styled.div`
  text-align: center;
`;

const CategoryTitle = styled.h2`
  font-size: ${toRem(14)};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colorScheme.black};

  ${theme.above.md} {
    font-size: ${toRem(18)};
  }
`;

const ImageWrapper = styled.div`
  width: 140px;
  height: 140px;
  background-color: ${theme.colorScheme.black};
  background-color: ${props =>
    props.iconColour ? props.iconColour : theme.colorScheme.primary};
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const CategoryItem = styled.div`
  display: flex;
  flex: 0 0 auto;
  position: relative;
  justify-content: center;
  align-items: start;

  ${theme.below.md} {
    max-width: min(34vw, 140px);
    margin-left: 1rem;
    margin-right: 1rem;
  }

  ${theme.above.sm} {
    min-width: initial;
    flex: 1;
  }

  ${ImageWrapper} {
    > div {
      transform: scale(1);
      animation: imgZoomOut 0.3s;
      transition-timing-function: ease-in-out;
    }
  }
  :hover {
    ${ImageWrapper} {
      > div {
        transform: scale(1.15);
        animation: imgZoomIn 0.5s;
        transition-timing-function: ease-in-out;
      }
    }
  }
  @keyframes hoverUp {
    from {
      padding-bottom: ${toRem(14)};
    }
    to {
      padding-bottom: ${toRem(20)};
    }
  }
  @keyframes imageOpacity {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.7;
    }
  }
  @keyframes imgZoomIn {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.15);
    }
  }
  @keyframes imgZoomOut {
    from {
      transform: scale(1.15);
    }
    to {
      transform: scale(1);
    }
  }
`;
const style = {
  CategoryBlockWrapper,
  CategoryItem,
  CategoryTextContent,
  CategoryTitle,
  ImageWrapper
};

export default style;
