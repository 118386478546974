import { css } from 'linaria';
import { theme } from './Theme';

const breadcrumbsStyles = css`
  text-transform: uppercase;
  a,
  li {
    color: ${theme.colorScheme.black};
  }
  ${theme.below.md} {
    margin-bottom: 1rem;
  }
  li:nth-of-type(5) {
    display: none;
  }
  li:nth-last-of-type(1) {
    display: none;
  }
  li:nth-of-type(1) {
    display: none;
  }
  color: rgb(255, 255, 255);

  li:nth-of-type(2) {
    color: rgb(255, 255, 255);
    margin-bottom: 1.25rem;
  }
  li:nth-of-type(3) {
    color: rgb(255, 255, 255);
  }
  li:nth-of-type(4) {
    color: rgb(255, 255, 255);
  }
  li:nth-of-type(2) > a,
  li:nth-of-type(3) > a,
  li:nth-of-type(4) > a {
    padding: 7px 15px;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(31, 31, 31);
  }
  li:after {
    content: '';
  }
`;

export default breadcrumbsStyles;
