import React, { useEffect, useState } from 'react';
import Styled from './Style';
import useChildren from '../../hooks/useChildren';
import FadeInComponent from '../../components/Animations/Animations';

const InstagramGallery = props => {
  const instagramBlock = useChildren(props);
  const [photos, setPhotos] = useState([]);
  const accessToken = instagramBlock.token;
  var rows = 2;
  var cols = 6;
  if (
    !isNaN(
      parseFloat(instagramBlock.rows) && !isNaN(parseFloat(instagramBlock.cols))
    )
  ) {
    rows = instagramBlock.rows;
    cols = instagramBlock.cols;
  }
  const limit = rows * cols;

  var aspect = '343/429';
  if (instagramBlock.aspect === 'Square') {
    aspect = '1/1';
  }

  let showImage = false;
  let showVideo = false;
  if (
    instagramBlock.content === 'Images' ||
    instagramBlock.content === 'Both'
  ) {
    showImage = true;
  }
  if (
    instagramBlock.content === 'Videos' ||
    instagramBlock.content === 'Both'
  ) {
    showVideo = true;
  }

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await fetch(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,thumbnail_url&access_token=${accessToken}&limit=24`
        );
        const data = await response.json();

        if (data.error) {
          console.log('Instagram session token timeout.');
          return false;
        }

        // Filter based on showImage and showVideo
        let filteredPhotos = data.data.filter(photo => {
          if (
            photo.media_type === 'IMAGE' ||
            photo.media_type === 'CAROUSEL_ALBUM'
          ) {
            return showImage;
          }
          if (photo.media_type === 'VIDEO') {
            return showVideo;
          }
          return false;
        });

        filteredPhotos = filteredPhotos.slice(0, limit);

        setPhotos(filteredPhotos);
      } catch (error) {
        console.error('Error fetching Instagram photos:', error);
      }
    };

    fetchPhotos();
  }, [accessToken, showImage, showVideo, limit]);

  if (!photos.length) return null;
  return (
    <Styled.InstagramWrapper>
      <Styled.InstagramTitle>{instagramBlock.title}</Styled.InstagramTitle>
      <Styled.InstagramGallery style={{ '--cols': cols }}>
        {photos.map(photo => (
          <FadeInComponent key={photo.id}>
            {(photo.media_type === 'IMAGE' ||
              photo.media_type === 'CAROUSEL_ALBUM') &&
            showImage ? (
              <a
                href={photo.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={photo.media_url}
                  alt="Instagram"
                  style={{ '--aspect': aspect }}
                />
              </a>
            ) : null}
            {photo.media_type === 'VIDEO' && showVideo ? (
              <a
                href={photo.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <video
                  src={photo.media_url}
                  controls
                  style={{ '--aspect': aspect }}
                />
              </a>
            ) : null}
          </FadeInComponent>
        ))}
      </Styled.InstagramGallery>
    </Styled.InstagramWrapper>
  );
};

export default InstagramGallery;
