import { styled } from 'linaria/react';
import MaxWidth from '../../components/Layout/MaxWidth';
import { toRem } from '../../helpers';
import { theme } from '../../components/Theme';
import Heading from '../../components/ui/Heading';

const HeroWrapper = styled(MaxWidth)`
  ${theme.below.lg} {
    padding-right: 0;
    padding-left: 0;
  }
  padding-right: ${toRem(36)};
  padding-left: ${toRem(36)};
  margin-bottom: 1rem;
`;

const HeroItemWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  color: ${theme.colorScheme.white};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${toRem(24)} ${toRem(20)};
  z-index: 10;

  ${theme.above.md} {
    padding: ${toRem(50)} ${toRem(55)};
  }
`;

const PreHeader = styled(Heading)`
  color: ${theme.colorScheme.white};
  font-size: ${toRem(14)};
  text-transform: uppercase;
  margin-bottom: 1rem;

  ${theme.above.md} {
    font-size: ${toRem(16)};
  }
`;

const PostHeader = styled(Heading)`
  color: ${theme.colorScheme.white};
  font-size: ${toRem(14)};
  text-transform: uppercase;
  margin-bottom: 1rem;

  ${theme.above.md} {
    font-size: ${toRem(16)};
  }
`;

const Header = styled(Heading)`
  color: ${theme.colorScheme.white};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${toRem(32)};
  line-height: ${toRem(35)};
  margin-bottom: 1rem;

  ${theme.above.sm} {
    max-width: 75%;
  }
  ${theme.above.md} {
    font-size: ${toRem(56)};
    line-height: ${toRem(58)};
  }
  ${theme.above.lg} {
    max-width: 50%;
  }
  ${theme.above.xl} {
    font-size: ${toRem(80)};
    line-height: ${toRem(83)};
  }
`;

const FrostedGlass = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  z-index: 2;

  div {
    width: ${toRem(700)};
    height: ${toRem(1000)};
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.15);
    transform: rotate(-15deg);
    backdrop-filter: blur(3.2px);

    ${theme.below.xl} {
      width: ${toRem(900)};
      height: ${toRem(1500)};
      top: ${toRem(-100)};
      left: ${toRem(-400)};
    }
    ${theme.below.md} {
      width: ${toRem(900)};
      height: ${toRem(1500)};
      top: ${toRem(-100)};
      left: ${toRem(-550)};
    }
    ${theme.above.xl} {
      width: ${toRem(900)};
      height: ${toRem(1500)};
      top: ${toRem(-100)};
      left: ${toRem(-300)};
    }
  }
  ${theme.below.sm} {
    display: none;
  }
`;

const ControlButton = styled.button`
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 20;
  background: ${theme.colorScheme.primary};
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: ${props => (props.videoLoaded ? 1 : -1)};
  opacity: ${props => (props.videoLoaded ? 1 : 0)};
  transition: opacity 1s;
  aspect-ratio: 16 / 9;
  object-position: center;
  overflow: hidden;

  ${theme.above.sm} {
    height: auto;
    max-height: 100%;
  }

  ${theme.below.md} {
    aspect-ratio: 1 / 1;
  }
`;

const Image = styled.div`
  opacity: ${props => (props.videoLoaded ? 0 : 1)};
  transition: opacity 1s;
`;

const style = {
  ContentWrapper,
  HeroWrapper,
  HeroItemWrapper,
  PreHeader,
  PostHeader,
  Header,
  FrostedGlass,
  ControlButton,
  Video,
  Image
};

export default style;
