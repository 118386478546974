import { styled } from 'linaria/react';
import { theme } from '../../components/Theme';

const ColouredQuoteWrapper = styled('div')`
  padding: 0;
  width: 100% ${theme.above.xl} {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
  }
`;
const ColouredQuote = styled('div')`
  padding: 0;
  width: 100%
  max-width: 45rem;
`;

const style = {
  ColouredQuoteWrapper,
  ColouredQuote
};

export default style;
