import React from 'react';
import { Below } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import Styled from './Style';
import CategoryLink from './CategoryLink';
import useChildren from '../../hooks/useChildren';

const CategoryIconBlock = props => {
  const cleanedProps = useChildren(props);
  const categories = cleanedProps['categoryIconItem'];

  if (!categories) return null;

  const aspectRatio = '1:1';
  const filteredCategories = categories.filter(
    ({ categoryIcon, internalLinkUrl }) => categoryIcon && internalLinkUrl
  );
  const catQty = filteredCategories.length ?? 1;
  const imageSizes = [1, 1, 1, 1 / catQty, 1 / catQty, 1440 / catQty];

  return (
    <Styled.CategoryBlockWrapper>
      {filteredCategories.map(category => {
        const title = category.title;
        return (
          <Styled.CategoryItem key={category.key}>
            <CategoryLink
              internalLink={category.internalLinkUrl}
              category={category}
            >
              <Below breakpoint="md">
                {matches =>
                  matches ? (
                    <Styled.ImageWrapper
                      className="below-md"
                      iconColour={category.colour}
                    >
                      <Image
                        sizes={imageSizes}
                        aspect={aspectRatio}
                        src={category.categoryIcon}
                        fillAvailableSpace={true}
                      />
                    </Styled.ImageWrapper>
                  ) : (
                    <Styled.ImageWrapper
                      className="above-md"
                      iconColour={category.colour}
                    >
                      <Image
                        sizes={imageSizes}
                        aspect={aspectRatio}
                        src={category.categoryIcon}
                        fillAvailableSpace={true}
                      />
                    </Styled.ImageWrapper>
                  )
                }
              </Below>
              {title ? (
                <Styled.CategoryTextContent>
                  <Styled.CategoryTitle>{title}</Styled.CategoryTitle>
                </Styled.CategoryTextContent>
              ) : null}
            </CategoryLink>
          </Styled.CategoryItem>
        );
      })}
    </Styled.CategoryBlockWrapper>
  );
};

export default CategoryIconBlock;
