import React, { useEffect, useRef, useState } from 'react';
import Image from '@jetshop/ui/Image';
import Styled from './Style';
import NonLinkCTA from '../../components/ui/NonLinkCTA';
import CTALink from '../../components/ui/Link';
import useChildren from '../../hooks/useChildren';
import { Below } from '@jetshop/ui/Breakpoints';
import { FaPlay, FaPause } from 'react-icons/fa';

const HeroVideoBlock = props => {
  const heroItem = useChildren(props);
  const [isInView, setIsInView] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!wrapperRef.current) return;

      const rect = wrapperRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const elementHeight = rect.height;
      const elementInView =
        rect.top + elementHeight / 2 <= windowHeight &&
        rect.bottom - elementHeight / 2 >= 0;
      const elementOutOfView = rect.bottom <= 0 || rect.top >= windowHeight;

      if (elementOutOfView) {
        setIsInView(false);
      } else if (elementInView) {
        setIsInView(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger on initial mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isInView && videoLoaded) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, [isInView, videoLoaded]);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };
  useEffect(() => {
    if (isInView && videoRef.current) {
      videoRef.current.play().catch(err => {
        console.error('Autoplay failed:', err);
      });
    }
  }, [isInView, videoLoaded]);

  if (!heroItem.backgroundVideo) return null;

  return (
    <Styled.HeroWrapper aria-label="hero carousel" ref={wrapperRef}>
      <Styled.HeroItemWrapper>
        <Below breakpoint="sm">
          {matches => (
            <Styled.Image>
              <Image
                className={matches ? 'below-sm' : 'above-sm'}
                src={heroItem.thumbnailImage}
                aspect={matches ? '1:1' : '16:9'}
                crop={true}
                cover
                sizes={[1, 1, 1, 1, 1, 1368]}
                style={{
                  opacity: videoLoaded ? 0 : 1,
                  visibility: videoLoaded ? 'hidden' : 'visible',
                  transition: 'opacity 1s, visibility 0s 1s'
                }}
              />
            </Styled.Image>
          )}
        </Below>
        <Styled.Video
          ref={videoRef}
          src={heroItem.backgroundVideo}
          muted
          playsInline
          loop
          videoLoaded={videoLoaded}
          onLoadedData={() => {
            setVideoLoaded(true);
          }}
          onCanPlay={() => {
            setVideoLoaded(true);
          }}
          onPlay={() => {
            setVideoLoaded(true);
          }}
          onError={e => console.error('Video error:', e)}
        />
        {heroItem.removeImageBlur === false && (
          <Styled.FrostedGlass>
            <div />
          </Styled.FrostedGlass>
        )}
        <Styled.ContentWrapper>
          {heroItem.preHeader && (
            <Styled.PreHeader level={4}>{heroItem.preHeader}</Styled.PreHeader>
          )}
          {heroItem.header && (
            <Styled.Header level={1}>{heroItem.header}</Styled.Header>
          )}
          {heroItem.postHeader && (
            <Styled.PostHeader level={4}>
              {heroItem.postHeader}
            </Styled.PostHeader>
          )}
          {heroItem.ctaLabel &&
            (heroItem.externalLinkUrl || heroItem.internalLinkUrl) && (
              <CTALink
                internalLink={heroItem.internalLinkUrl}
                externalLink={heroItem.externalLinkUrl}
              >
                {heroItem.ctaLabel}
              </CTALink>
            )}
          {heroItem.ctaLabel &&
            !heroItem.externalLinkUrl && !heroItem.internalLinkUrl && (
              <NonLinkCTA>{heroItem.ctaLabel}</NonLinkCTA>
            )}
        </Styled.ContentWrapper>
        <Styled.ControlButton onClick={togglePlayPause}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </Styled.ControlButton>
      </Styled.HeroItemWrapper>
    </Styled.HeroWrapper>
  );
};

export default HeroVideoBlock;
