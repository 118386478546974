import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../../components/Layout/MaxWidth';
import { theme } from '../../components/Theme';
import breadcrumbsStyles from '../../components/breadcrumbsStyles';
import ImageBlock from '../../blocks/ImageBlock/ImageBlock';
import Heading from '../../components/ui/Heading';

export const CategoryHeaderWrapper = styled(MaxWidth)`
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;

  ${theme.below.xl} {
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
`;

export const NoImageWrapper = styled('div')`
  margin-top: 0.1rem;
  padding: 2px;
`;

const CategoryName = styled(Heading)`
  font-weight: 600;
  line-height: normal;
  font-size: 40px;
  margin-bottom: 0px;
  ${theme.below.md} {
    font-size: 30px;
  }
`;

const CategoryContent = styled('div')`
  max-width: 540px;
  font-weight: 300;
  line-height: 26px;
  font-size: 18px;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 8px;
  margin-top: 5px;
  padding: 0 2rem;
`;

export const Inner = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Content = ({ category, breadcrumbProps, content }) => (
  <Inner>
    <CategoryName level="1" data-testid="page-header">
      {category.name}
    </CategoryName>
    <CategoryContent
      data-testid="category-description"
      dangerouslySetInnerHTML={{
        __html: content?.value || category.content
      }}
    />
  </Inner>
);

const CategoryHeader = ({
  category,
  parents,
  imageSrc,
  breadcrumbs = { value: true },
  content
}) => {
  const { value } = breadcrumbs;
  const breadcrumbProps = {
    breadcrumbText: value ? category.breadcrumbText : '',
    parents
  };
  const isImageBackground =
    imageSrc || (category.images && category.images.length > 0);
  const hasParents = breadcrumbProps.parents.length > 0;
  return (
    <CategoryHeaderWrapper>
      {hasParents ? (
        <StyledBreadcrumbs
          {...breadcrumbProps}
          hideLast={true}
          className={breadcrumbsStyles}
        />
      ) : (
        <StyledBreadcrumbs />
      )}
      {isImageBackground ? (
        <ImageBlock
          image={imageSrc?.value || category.images}
          title={category.name}
          content={content}
        />
      ) : (
        <NoImageWrapper>
          <Content category={category} content={content} />
        </NoImageWrapper>
      )}
    </CategoryHeaderWrapper>
  );
};

export default CategoryHeader;
