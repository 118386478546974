import { styled } from 'linaria/react';
import MaxWidth from '../../components/Layout/MaxWidth';
import { toRem } from '../../helpers';
import { theme } from '../../components/Theme';
import Heading from '../../components/ui/Heading';

const HeroWrapper = styled(MaxWidth)`
  ${theme.below.lg} {
    padding-right: 0;
    padding-left: 0;
  }
  padding-right: ${toRem(36)};
  padding-left: ${toRem(36)};
  margin-bottom: 1rem;
  ${theme.above.lg} {
    div {
      border-radius: ${props => (props.rounded ? '30px' : '0px')};
    }
  }
`;

const HeroItemWrapper = styled.div``;

const ContentWrapper = styled.div`
  color: ${theme.colorScheme.white};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${toRem(24)} ${toRem(20)};
  z-index: 10;

  ${theme.above.md} {
    padding: ${toRem(50)} ${toRem(55)};
  }
`;

const PreHeader = styled(Heading)`
  color: ${theme.colorScheme.white};
  font-size: ${toRem(14)};
  text-transform: uppercase;
  margin-bottom: ${toRem(20)};

  ${theme.above.md} {
    font-size: ${toRem(16)};
  }
`;

const Header = styled(Heading)`
  color: ${theme.colorScheme.white};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${toRem(32)};
  line-height: ${toRem(35)};
  margin-bottom: ${toRem(20)};

  ${theme.above.sm} {
    max-width: 75%;
  }
  ${theme.above.md} {
    font-size: ${toRem(56)};
    line-height: ${toRem(58)};
    margin-bottom: ${toRem(40)};
  }
  ${theme.above.lg} {
    max-width: 50%;
  }
  ${theme.above.xl} {
    font-size: ${toRem(80)};
    line-height: ${toRem(83)};
  }
`;

const DotContainer = styled('div')`
  display: none;

  ${theme.above.md} {
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: ${toRem(56)};
  }
`;

const DotButton = styled.button`
  margin-bottom: ${toRem(8)};
  padding: ${toRem(2)};
  border-radius: 50%;
  border: 1px solid ${theme.colorScheme.white};
  background-color: transparent;

  :before {
    content: '';
    height: ${toRem(8)};
    width: ${toRem(8)};
    display: block;
    background: ${props =>
      props.isActive ? theme.colorScheme.white : 'transparent'};
    position: relative;
    left: 0;
    border-radius: 50%;
  }
`;

const FrostedGlass = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;

  div {
    width: ${toRem(700)};
    height: ${toRem(700)};
    position: absolute;
    top: 0;
    left: ${toRem(-450)};
    background: rgba(0, 0, 0, 0.15);
    transform: rotate(75deg);
    backdrop-filter: blur(3.2px);

    ${theme.above.md} {
      width: ${toRem(900)};
      height: ${toRem(900)};
      top: ${toRem(-100)};
      left: ${toRem(-400)};
    }
  }
`;

const style = {
  ContentWrapper,
  HeroWrapper,
  HeroItemWrapper,
  PreHeader,
  Header,
  DotContainer,
  DotButton,
  FrostedGlass
};

export default style;
